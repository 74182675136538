/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { NextPage, GetServerSideProps } from 'next';
import Head from 'next/head';
import BasePage from '@/src/components/common/BasePage';

import { lightSendPv } from '@/src/sources/common/lightUbt';
import SEOListForm from '@/src/SEOComponents/seolist';
import SEOFooterForm from '@/src/SEOComponents/seofooter';
import Skeleton from '@/src/components/loading/list';
import List from '@/src/containers/list/ListContainerSEO/async';

import '@/public/style/searchbox.css';
import '@/public/style/listloading.css';
import '@/public/style/list.css';

import '@/src/styles/list/todo/variable.scss';
import '@/src/styles/list/todo/book-tooltip.scss';
import '@/src/styles/list/todo/smallstyle.scss';
import '@/src/styles/list/todo/servicePackage.scss';
import '@/src/styles/list/todo/flightOnlineListIcon/flightOnlineListIcon.scss';
import '@/src/styles/list/todo/footer.breadcrumb.index.scss';
import '@/src/styles/list/todo/notice.scss';
import '@/src/styles/list/todo/list.scss';
import '@/src/styles/list/todo/list-override.scss';

type ListPageOut = Record<string, any>;

enum FlightWayType {
	S = 'S',
	D = 'D',
	M = 'M',
}

/**
 * UBT埋点-PageId:10650088566
 * @param scope
 * @param flightWay
 */
function getPageId(scope: string, flightWay: FlightWayType) {
	if (scope === 'd') {
		if (flightWay == FlightWayType.S) {
			return '10650088566';
		} else if (flightWay == FlightWayType.D) {
			return '10650088566';
		} else {
			return '10650088566';
		}
	} else {
		if (flightWay == FlightWayType.S) {
			return '10650088566';
		} else if (flightWay == FlightWayType.D) {
			return '10650088566';
		} else {
			return '10650088566';
		}
	}
}

/**
 * 页面main service res props
 * @param props
 */
const index: NextPage<ListPageOut> = ({ renderData, setCurrentPageId }) => {
	const [firstRender, setFirstRender] = useState(true);
	const { GlobalSearchCriteria, GlobalConfigs, fuzzySearchCityRes, fuzzySearchHotRes, seo, seoUrl } =
		renderData || {};

	const { scope, flightWay } = GlobalSearchCriteria;
	const pageId = getPageId(scope, flightWay);

	useEffect(() => {
		window.SEOFlag = true;
		var c = 'PRE_SEARCH_COST_TIME',
			d = sessionStorage.getItem(c);
		if (d)
			try {
				var e = JSON.parse(d);
				e && e.s0 && ((e.s1 = +new Date()), sessionStorage.setItem(c, JSON.stringify(e)));
			} catch (f) {
				console.error(f);
			}
		setFirstRender(false);
	}, []);

	useEffect(() => {
		if (!firstRender) {
			setCurrentPageId(pageId);
			lightSendPv(pageId);
		}
	}, [firstRender, pageId]);

	return (
		<>
			<Head>
				<meta name="description" content={seo.description} />
				<meta name="keywords" content={seo.keywords} />
				<meta httpEquiv="Cache-Control" content="public" />
				<title>{seo.title}</title>
				{!!seoUrl && (
					<>
						<meta name="applicable-device" content="pc" />
						<meta
							name="mobile-agent"
							content={`format=html5;url=https://m.ctrip.com/html5/flight/${seoUrl}.html`}
						/>
						<link
							rel="alternate"
							media="only screen and (max-width: 640px)"
							href={`https://m.ctrip.com/html5/flight/${seoUrl}.html`}
						/>
						<link rel="canonical" href={`https://flights.ctrip.com/booking/${seoUrl}.html`} />
						<meta httpEquiv="Cache-Control" content="no-transform " />
					</>
				)}
				{fuzzySearchCityRes && fuzzySearchCityRes?.routes?.length === 0 ? (
					<meta name="robots" content="nofollow,noarchive"></meta>
				) : (
					<meta name="robots" content="index,follow" />
				)}
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							'@context': 'https://ziyuan.baidu.com/contexts/cambrian.jsonld',
							'@id': ' https://flights.ctrip.com/booking/' + seoUrl + '.html',
							title: seo.title,
							description: seo.description,
							pubDate: '2022-11-11T08:00:01',
							upDate: new Date(GlobalSearchCriteria.flightSegments[0].departureDate).toISOString(),
						}),
					}}
				></script>
			</Head>
			<input type="hidden" id="page_id" value={pageId} />
			<input type="hidden" id="page_start_time" value={+new Date()} />
			{firstRender ? <Skeleton /> : React.createElement(List)}
			<SEOListForm
				fuzzySearchCityRes={fuzzySearchCityRes}
				globalSearchCriteria={GlobalSearchCriteria}
			></SEOListForm>
			<SEOFooterForm
				globalSearchCriteria={GlobalSearchCriteria}
				globalConfigs={GlobalConfigs}
				fuzzySearchHotRes={fuzzySearchHotRes}
			></SEOFooterForm>
		</>
	);
};

index.displayName = 'page(list)';

export const getServerSideProps: GetServerSideProps<ListPageOut> = async (context) => {
	const { req } = context;
	const { renderData, aresModules, aresManifest, aresAssetPrefix } = req.headers;
	return { props: { renderData, aresModules, aresManifest, aresAssetPrefix } };
};

export default BasePage({})(index);
