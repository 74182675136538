import React, { useEffect } from 'react';
import SEOFooterBreadcrumbForm from './tsxFloder/footerContentComponents/breadcrumb';
import SEOFooterSixProtectionSmallForm from './tsxFloder/footerContentComponents/sixprotectionsmall';
import SEOFooterDisclaimerForm from './tsxFloder/footerContentComponents/disclaimer';
import SEOFooterLinkForm from './tsxFloder/footerContentComponents/footerlink';

import './scssFolder/seofooter.scss';

interface SEOListFormProps {
	globalSearchCriteria: any;
	globalConfigs: any;
	fuzzySearchHotRes: any;
}

/**
 * SEO Online Footer
 * 安心定&放心飞
 * 航班信息免责声明
 * 携程网提供：
 * @param props
 */
const SEOFooterForm: React.FC<SEOListFormProps> = (props) => {
	const { globalSearchCriteria, globalConfigs, fuzzySearchHotRes } = props;
	console.log('Hot:' + JSON.stringify(fuzzySearchHotRes));

	useEffect(() => {}, []);

	return (
		<>
			<div className="footer-component">
				<div className="footer-component-wrapper">
					<SEOFooterBreadcrumbForm
						flightSegments={globalSearchCriteria.flightSegments}
					></SEOFooterBreadcrumbForm>
					<SEOFooterSixProtectionSmallForm globalConfigs={globalConfigs}></SEOFooterSixProtectionSmallForm>
					<SEOFooterDisclaimerForm></SEOFooterDisclaimerForm>
					<SEOFooterLinkForm
						globalConfigs={globalConfigs}
            flightSegments={globalSearchCriteria.flightSegments}
            fuzzySearchHotRes={fuzzySearchHotRes}
					></SEOFooterLinkForm>
				</div>
			</div>
		</>
	);
};

export default SEOFooterForm;
