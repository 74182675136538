import React, { useEffect } from 'react';

interface SEOFooterLinkInfoFormProps {
	globalConfigs: any;
	flightSegments: any;
}

/**
 * SEO Online Footer FooterLink
 * 旅行助手
 * 机票工具箱
 * 打折机票
 * 航空机票
 * @param props
 */
const SEOFooterLinkInfoForm: React.FC<SEOFooterLinkInfoFormProps> = (props) => {
	const { globalConfigs, flightSegments } = props;
	const departureCityCode = flightSegments[0].departureCityCode;
	const departureCity = flightSegments[0].departureCityName;
	const arrivalCity = flightSegments[0].arrivalCityName;
	const arrivalCityCode = flightSegments[0].arrivalCityCode;

	const replaceTemplateCode = (templateCotnent, replaceData) => {
		const regex = /(\{departureCityCode\})|(\{departureCity\})|(\{arrivalCityCode\})|(\{arrivalCity\})/g;
		return templateCotnent.replace(regex, ($0) => {
			let replaceKey = $0.substr(1, $0.length - 2);
			if (replaceData[replaceKey]) {
				return replaceData[replaceKey];
			} else {
				return $0;
			}
		});
	};

	useEffect(() => {}, []);

	return (
		<>
			{globalConfigs.domesticSeoData.datas.map((data, index) => {
				let { title, items } = data;
				return (
					<p key={index}>
						<span className="caption">{title}</span>
						{items.map((item, index) => {
							let { text, link } = item,
								replaceData = {
									departureCityCode,
									departureCity,
									arrivalCity,
									arrivalCityCode,
								};
							let realText = replaceTemplateCode(text, replaceData),
								realLink = replaceTemplateCode(link, replaceData);
							return (
								<span key={index} className="links">
									<a href={realLink}>{realText}</a>
								</span>
							);
						})}
					</p>
				);
			})}
		</>
	);
};

export default SEOFooterLinkInfoForm;
