import React, { useEffect } from 'react';

import '../../scssFolder/seolist.scss';

interface SEOListContentNoResultFormProps {
	flightSegments: any;
}

/**
 * SEO Online List No Result
 * @param props
 */
const SEOListContentNoResultForm: React.FC<SEOListContentNoResultFormProps> = (props) => {
	const { flightSegments } = props;
	const data = flightSegments[0];

	useEffect(() => {}, []);

	return (
		<>
			<div className="seo-no-result">
				<i className="seo-icon-noresult"></i>
				<div className="seo-noreuslt-title">
					<span>您查询的</span>
					<span>{data.departureCityName}</span>
					<span>(</span>
					<span>{data.departureCityCode}</span>
					<span>)</span>
					<span>至</span>
					<span>{data.arrivalCityName}</span>
					<span>(</span>
					<span>{data.arrivalCityCode}</span>
					<span>)</span>
					<span>（出发日期：</span>
					<span>{data.departureDate}</span>
					<span>)</span>
					<span>
						的机票可能因无航班或航班座位已售完导致暂时无法查询到对应价格。
						建议您更换旅行日期或旅行城市重新查询
					</span>
				</div>
			</div>
		</>
	);
};

export default SEOListContentNoResultForm;
