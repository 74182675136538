import React, { useEffect } from 'react';

import '../../scssFolder/seolist.scss';

interface SEOFooterBreadcrumbFormProps {}

/**
 * SEO Online Footer Disclaimer 航班信息免责声明
 * @param props
 */
const SEOFooterDisclaimerForm: React.FC<SEOFooterBreadcrumbFormProps> = () => {

	useEffect(() => {}, []);

	return (
		<>
			<div className="disclaimer">
				<h3>航班信息免责声明:</h3>
				<p>
					携程旅行网提供的各类航班相关的信息，包括但不限于舱位价格、餐食、起降准点率等，来源于中国航信、全国各大机场、航空公司及相关部门公布数据。由于这些信息可能随时变化，以及网络传输问题，携程提供的此类信息可能并非最新或存在误差，因此仅供旅客作为一般参考，任何公司或个人不能将此作为携程做出任何承诺或做出任何保证的依据。
				</p>
			</div>
		</>
	);
};

export default SEOFooterDisclaimerForm;
