import React, { useEffect } from 'react';
import moment from 'moment';

import '../scssFolder/seoheader.scss';

interface SEOListHeaderFormProps {
	flightSegments: any;
}

/**
 * SEO Online List header
 * @param props
 */
const SEOListHeaderForm: React.FC<SEOListHeaderFormProps> = (props) => {
	const { flightSegments } = props;
	const date = moment(flightSegments[0].departureDate);

	useEffect(() => {}, []);
	return (
		<>
			<div className="list-header">
				<div className="city-info">
					<span className="tripType">单程 ：</span>
					<span className="departCity">{flightSegments[0].departureCityName}</span>
					<i className="arrow-gray"></i>
					<span className="arriveCity">{flightSegments[0].arrivalCityName}</span>
				</div>
				<div className="date-info">
					<span className="day">{date.format('MM月DD日')}</span>
					<span className="week">{`周${'一二三四五六日'.charAt(date.isoWeekday() - 1)}`}</span>
				</div>
				<span></span>
			</div>
		</>
	);
};

export default SEOListHeaderForm;
