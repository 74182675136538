import React from 'react';

import '../../../styles/protection/smallstyle.scss';

interface SEOFooterSixProtectionSmallFormProps {
	globalConfigs: any;
}

/**
 * SEO Online Footer SixProtectionSmall 安心定&放心飞
 * @param props
 */
const SEOFooterSixProtectionSmallForm: React.FC<SEOFooterSixProtectionSmallFormProps> = (props) => {
	const { globalConfigs } = props;
	const jumpUrl =
		(globalConfigs && globalConfigs.protectionJumpUrl) ||
		'https://contents.ctrip.com/activitysetupapp/mkt/index/pc#flight';
	const imgUrlV2 = (globalConfigs && globalConfigs.protectionImgV2) || {};

	return (
		<>
			<div className="six_protection_small">
				<div className="six_protection_body">
					<div className="six_protection_logo">
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							<img src={imgUrlV2.logo} style={{ width: 168 }} alt="安心定放心飞" />
						</a>
					</div>
					<div className="six_protection_content">|</div>
					<div className="six_protection_content">
						<img src={imgUrlV2.xingcheng} alt="行程保障" />
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							行程保障
						</a>
					</div>
					<div className="six_protection_content">|</div>
					<div className="six_protection_content">
						<img src={imgUrlV2.jiage} alt="价格保障" />
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							价格保障
						</a>
					</div>
					<div className="six_protection_content">|</div>
					<div className="six_protection_content">
						<img src={imgUrlV2.kefu} alt="7*24小时客服" />
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							7*24小时客服
						</a>
					</div>
					<div className="six_protection_content">|</div>
					<div className="six_protection_content">
						<img src={imgUrlV2.yingji} alt="应急援助" />
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							应急援助
						</a>
					</div>
					<div className="six_protection_content">|</div>
					<div className="six_protection_content">
						<img src={imgUrlV2.anxin} alt="安心1 + 1" />
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							安心1 + 1
						</a>
					</div>
					<div className="six_protection_content">|</div>
					<div className="six_protection_content">
						<img src={imgUrlV2.jiaotong} alt="组合交通出行" />
						<a href={jumpUrl} target="_blank" rel="noreferrer">
							组合交通出行
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default SEOFooterSixProtectionSmallForm;
