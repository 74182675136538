import React, { useEffect } from 'react';

interface SEOFooterHotFlightFormProps {
	fuzzySearchHotRes: any;
}

/**
 * SEO Online Footer FooterLink
 * 热门机票
 * @param props
 */
const SEOFooterHotFlightForm: React.FC<SEOFooterHotFlightFormProps> = (props) => {
	const { fuzzySearchHotRes } = props;
	const fuzzySearchHotResData = JSON.parse(fuzzySearchHotRes.result);

	useEffect(() => {}, []);

	return (
		<>
			<p>
				<span className="caption">热门机票</span>
				{fuzzySearchHotResData.map((data, index) => {
					return data.dcityname &&
						data.acityname &&
						/^[a-zA-Z]{3,4}$/.test(data.dcity) &&
						/^[a-zA-Z]{3,4}$/.test(data.acity) ? (
						<span key={index} className="links">
							<a href={'/booking/' + data.dcity + '-' + data.acity + '-day-1.html'}>
								<span>{data.dcityname}</span>
								<span>到</span>
								<span>{data.acityname}</span>
								<span>机票</span>
							</a>
						</span>
					) : null;
				})}
			</p>
		</>
	);
};

export default SEOFooterHotFlightForm;
