import React, { useEffect } from 'react';
import Oneway from './listContentComponents/oneway';
import Transit from './listContentComponents/transit';
import NoResult from './listContentComponents/noresult';

interface SEOListContentFormProps {
	flightSegments: any;
	fuzzySearchCityRes: any;
}

/**
 * SEO Online List content
 * @param props
 */
const SEOListContentForm: React.FC<SEOListContentFormProps> = (props) => {
	const { flightSegments, fuzzySearchCityRes } = props;

	useEffect(() => {}, []);

	return (
		<>
			{fuzzySearchCityRes.routes && fuzzySearchCityRes.routes.length > 1 ? (
				<div className="list-content">
					{fuzzySearchCityRes.routes.map((item, key) => {
						if (item.flights.length === 1) {
							return <Oneway item={item} key={key} flightSegments={flightSegments}></Oneway>;
						} else {
							return <Transit item={item} key={key} flightSegments={flightSegments}></Transit>;
						}
					})}
				</div>
			) : (
				<NoResult flightSegments={flightSegments}></NoResult>
			)}
		</>
	);
};

export default SEOListContentForm;
