import React, { useState, useEffect } from 'react';

interface SEOFooterTripProvideFormProps {
	flightSegments: any;
}

/**
 * SEO Online Footer FooterLink
 * 携程网提供
 * @param props
 */
const SEOFooterTripProvideForm: React.FC<SEOFooterTripProvideFormProps> = (props) => {
	const { flightSegments } = props;
	const data = flightSegments[0];

	useEffect(() => {}, []);

	return (
		<>
			<p>
				携程网提供
				<span className="links">
					<a href="//flights.ctrip.com/">特价机票</a>
				</span>
				,
				<span className="links">
					<a href="//flights.ctrip.com/schedule/">航班时刻表</a>
				</span>
				,
				<span className="links">
					<a href="//flights.ctrip.com/actualtime/">航班动态查询</a>
				</span>
				:
			</p>
			<p className="p_text">
				携程机票为您提供
				<span className="links">
					<a
						href={`//flights.ctrip.com/booking/lowprice-${data.departureCityCode}-${data.arrivalCityCode}----adu-1/`}
					>
						{data.departureCityName}到{data.arrivalCityName}特价机票
					</a>
				</span>
				,以及
				<span className="links">
					<a href="//flights.ctrip.com/booking/china-city-flights-sitemap.html">飞机票价格查询</a>
				</span>
				,
				<span className="links">
					<a href="//flights.ctrip.com/hot-flights.html">热门机票</a>
				</span>
				,
				<span className="links">
					<a href="//flights.ctrip.com/hot-airport.html">机场查询</a>.现在
					<a
						rel="nofollow noopener noreferrer"
						href="//passport.ctrip.com/user/reg/home"
						target="_blank"
					>
						注册
					</a>
				</span>
				携程会员即可获得1200积分和1300元消费券!
			</p>
		</>
	);
};

export default SEOFooterTripProvideForm;
