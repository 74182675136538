import React, { useEffect } from 'react';

import SEOFooterTripProvideForm from './footerLink/tripprovide';
import SEOFooterLinkInfoForm from './footerLink/linkinfo';
import SEOFooterHotFlightForm from './footerLink/hotflights';

import '../../scssFolder/seofooterlink.scss';

interface SEOFooterLinkFormProps {
	flightSegments: any;
	globalConfigs: any;
	fuzzySearchHotRes: any;
}

/**
 * SEO Online Footer FooterLink
 * 携程网提供
 * 旅行助手
 * 机票工具箱
 * 打折机票
 * 航空机票
 * 热门排行榜
 * @param props
 */
const SEOFooterLinkForm: React.FC<SEOFooterLinkFormProps> = (props) => {
	const { flightSegments, globalConfigs, fuzzySearchHotRes } = props;

	useEffect(() => {}, []);

	return (
		<>
			<div className="seo-footer-links">
				<SEOFooterTripProvideForm flightSegments={flightSegments}></SEOFooterTripProvideForm>
				<SEOFooterLinkInfoForm
					flightSegments={flightSegments}
					globalConfigs={globalConfigs}
				></SEOFooterLinkInfoForm>
				{fuzzySearchHotRes && fuzzySearchHotRes.result.length !== 0 ? (
					<SEOFooterHotFlightForm fuzzySearchHotRes={fuzzySearchHotRes}></SEOFooterHotFlightForm>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default SEOFooterLinkForm;
