import React, { useEffect } from 'react';

interface SEOFooterBreadcrumbFormProps {
	flightSegments: any;
}

/**
 * SEO Online Footer Breadcrumb
 * @param props
 */
const SEOFooterBreadcrumbForm: React.FC<SEOFooterBreadcrumbFormProps> = (props) => {
	const { flightSegments } = props;
	const data = flightSegments[0];

	useEffect(() => {}, []);

	return (
		<>
			<div className="seo-breadcrumb">
				<h3>
					<a href="https://www.ctrip.com/" title="携程">
						携程
					</a>
				</h3>
				<span>&gt;</span>
				<h3>
					<a href="https://flights.ctrip.com/" title="机票">
						机票
					</a>
				</h3>
				<span>&gt;</span>
				<h3>
					{data.departureCityName}到{data.arrivalCityName}机票
				</h3>
			</div>
		</>
	);
};

export default SEOFooterBreadcrumbForm;
