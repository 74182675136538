import React, { useEffect } from 'react';
import moment from 'moment';

import '../../scssFolder/seocontentoneway.scss';

interface SEOListContentOnewayFormProps {
	flightSegments: any;
	item: any;
	key: any;
}

/**
 * SEO Online List content oneway 单程
 * @param props
 */
const SEOListContentOnewayForm: React.FC<SEOListContentOnewayFormProps> = (props) => {
	const { item, key, flightSegments } = props;

	const { airline, flightNo, craft, dtime, dport, atime, daySpan, aport } = item.flights[0] || {};

	useEffect(() => {}, []);

	return (
		<>
			<div className="list-content-item-oneway" key={key}>
				<div className="item-box">
					<div className="box-row">
						<div className="airline">
							<img
								className="airline-logo"
								src={`//pic.c-ctrip.com/AssetCatalog/airline/48/${airline.code}.png?v=2`}
								alt={airline.name}
							></img>
							<div className="airline-name">{airline.name}</div>
							<div className="plane">
								<span className="plane-No">
									{flightNo}
									<span>&nbsp;</span>
									<span className="">
										<span>{craft?.name}</span>
										<span>{craft?.type ? `(${craft.type})` : ''}</span>
									</span>
								</span>
								<span className="plane-service"></span>
							</div>
						</div>
						<div className="detail">
							<div className="depart-box">
								<div className="time">{moment(dtime).format('HH:mm')}</div>
								<div className="airport">
									<span>{dport.fullName}</span>
									<span>{dport.terminal}</span>
								</div>
							</div>
							<div className="arrow-box">
								<i className="arrow-oneway"></i>
							</div>
							<div className="arrive-box">
								<div className="time">
									<div>{moment(atime).format('HH:mm')}</div>
									{daySpan !== 0 ? <span className="day">+{daySpan}天</span> : ''}
								</div>
								<div className="airport">
									<span>{aport.fullName}</span>
									<span>{aport.terminal}</span>
								</div>
							</div>
						</div>
						{/* <div className="arrival-punctuality"></div> */}
						{/* <div className="flight-tags"></div> */}
					</div>
					<div className="box-operate">
						<div className="price">
							<div className="price-detail">
								<span className="price">
									<dfn>¥</dfn>
									{item.pl[0].price}
								</span>
								<span className="qi">起</span>
							</div>
							<div className="rate-detail">
								<span>{item.pl[0].className}</span>
								{item.pl[0].rate !== 0 ? (
									<span>
										{(item.pl[0].rate * 10).toString().length > 5
											? (item.pl[0].rate * 10).toFixed(1)
											: item.pl[0].rate * 10}
										<span>折</span>
									</span>
								) : (
									''
								)}
							</div>
						</div>
						<div
							className="action"
							onClick={() => {
								window.location.href =
									'/online/list/oneway-' +
									(flightSegments[0]?.departureAirportCode || flightSegments[0].departureCityCode) +
									'-' +
									(flightSegments[0]?.arrivalAirportCode || flightSegments[0].arrivalCityCode) +
									'?depdate=' +
									flightSegments[0].departureDate +
									'&airline=' +
									airline.code +
									'';
							}}
						>
							<button className="btn-book">订票</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SEOListContentOnewayForm;
