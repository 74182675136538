import React, { useEffect } from 'react';
import moment from 'moment';

import '../../scssFolder/seocontenttransit.scss';

interface SEOListContentTransitFormProps {
	item: any;
	key: any;
	flightSegments: any;
}
/**
 * SEO Online List content transit 中转
 * @param props
 */
const SEOListContentTransitForm: React.FC<SEOListContentTransitFormProps> = (props) => {
	const { item, key, flightSegments } = props;

	function formatMinAsDayHourMin(min, format) {
		const days: any = Math.floor(min / (24 * 60)),
			hours: any = Math.floor((min % (24 * 60)) / 60),
			mins: any = min % 60;
		format = days > 0 ? format.replace(/D+/g, days) : format.replace(/.*(?=HH)/g, '');
		format = hours > 0 ? format.replace(/H+/g, hours) : format.replace(/H.*(?=MM)/g, '');
		format = mins > 0 ? format.replace(/M+/g, mins) : format.replace(/MM.*/g, '');
		return format;
	}

	/**
	 *     'DDdHHhMMm'
	 * 时间换算
	 */
	function flightDateConvert(flightDate: any, format) {
		const endDate: number = new Date(flightDate.flights[1].dtime).getTime();
		const beginDate: number = new Date(flightDate.flights[0].atime).getTime();
		const minTime: number = (endDate - beginDate) / (1000 * 60);
		return formatMinAsDayHourMin(minTime, format);
	}

	useEffect(() => {}, []);

	const { dtime, dport, aport, daySpan, airline } = item.flights[0] || {};

	return (
		<>
			<div className="list-content-item-transit" key={key}>
				<div className="item-box">
					<div className="box-row">
						<div className="airline">
							{item.flights.map((v, k) => {
								return (
									<div key={k} className="airline-item">
										<img
											className="airline-logo"
											src={`//pic.c-ctrip.com/AssetCatalog/airline/48/${v.airline.code}.png?v=2`}
											alt={v.airline.name}
											key={k}
										></img>
										<div className="airline-name">{v.airline.name}</div>
										<div className="plane">
											<span className="plane-No">
												{v.flightNo}
												<span>&nbsp;</span>
												<span className="">
													<span>{v.craft?.name}</span>
													<span>{v.craft?.type ? `(${v.craft.type})` : ''}</span>
												</span>
											</span>
											<span className="plane-service"></span>
										</div>
									</div>
								);
							})}
						</div>

						<div className="detail">
							<div className="depart-box">
								<div className="time">{moment(dtime).format('HH:mm')}</div>
								<div className="airport">
									<span>{dport.fullName}</span>
									<span>{dport.terminal}</span>
								</div>
							</div>
							<div className="arrow-box">
								{item.flights.length === 2 ? (
									<span className="transfer-duration">
										<span>中转</span>
										{flightDateConvert(item, 'DD天HH小时MM分')}
									</span>
								) : (
									''
								)}

								<i className="arrow-oneway">
									<span>
										<span>转</span>
										<span>{item.flights.length - 1}</span>
										<span>次</span>
									</span>
								</i>
								{item.flights.length === 2 ? (
									<span className="transfer-info">
										<i>转</i>
										<span>{aport.name}</span>
										<span>{flightDateConvert(item, 'DDdHHhMMm')}</span>
									</span>
								) : (
									''
								)}
							</div>
							<div className="arrive-box">
								<div className="time">
									<div>{moment(item.flights[item.flights.length - 1].atime).format('HH:mm')}</div>
									{daySpan !== 0 ? <span className="day">+{daySpan}天</span> : ''}
								</div>
								<div className="airport">
									<span>{item.flights[item.flights.length - 1].aport.fullName}</span>
									<span>{item.flights[item.flights.length - 1].aport.terminal}</span>
								</div>
							</div>
						</div>
						{/* <div className="arrival-punctuality"></div> */}
						{/* <div className="flight-tags"></div> */}
					</div>
					<div className="box-operate">
						<div className="price">
							<div className="price-detail">
								<span className="price">
									<dfn>¥</dfn>
									{item.pl[0].price}
								</span>
								<span className="qi">起</span>
							</div>
							<div className="rate-detail">
								{/*  中转航班不显示折扣 */}
								{item.flights.map((v1, k1) => {
									return (
										<div key={k1} className="className">
											{item.pl[0].className}
										</div>
									);
								})}

								{item.pl[0].rate !== 0 ? (
									<span>
										{(item.pl[0].rate * 10).toString().length > 5
											? (item.pl[0].rate * 10).toFixed(1)
											: item.pl[0].rate * 10}
										<span>折</span>
									</span>
								) : (
									''
								)}
							</div>
						</div>
						<div
							className="action"
							onClick={() => {
								window.location.href =
									'/online/list/oneway-' +
									(flightSegments[0]?.departureAirportCode || flightSegments[0].departureCityCode) +
									'-' +
									(flightSegments[0]?.arrivalAirportCode || flightSegments[0].arrivalCityCode) +
									'?depdate=' +
									flightSegments[0].departureDate +
									'&airline=' +
									airline.code +
									'';
							}}
						>
							<button className="btn-book">订票</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SEOListContentTransitForm;
