import React, { useEffect } from 'react';
import SEOListHeaderForm from './tsxFloder/listheader';
import SEOListContentForm from './tsxFloder/listcontent';

import './scssFolder/seolist.scss';

interface SEOListFormProps {
	fuzzySearchCityRes: any;
	globalSearchCriteria: any;
}

/**
 * SEO Online List
 * @param props
 */
const SEOListForm: React.FC<SEOListFormProps> = (props) => {
	const { fuzzySearchCityRes, globalSearchCriteria } = props;
	console.log('City:' + JSON.stringify(fuzzySearchCityRes || null));

	useEffect(() => {}, []);

	return (
		<>
			<div className="seo-result-wrapper">
				<div className="seo-flight-list">
					{fuzzySearchCityRes.routes.length !== 0 ? (
						<SEOListHeaderForm flightSegments={globalSearchCriteria.flightSegments}></SEOListHeaderForm>
					) : (
						''
					)}

					<SEOListContentForm
						flightSegments={globalSearchCriteria.flightSegments}
						fuzzySearchCityRes={fuzzySearchCityRes}
					></SEOListContentForm>
				</div>
			</div>
		</>
	);
};

export default SEOListForm;
